export type PriceType = 'range' | 'fixed' | 'invisible' | 'discount' | 'free'

export interface LocalProductCategory {
  id: number;
  name: {
      es: string;
      en: string;
  };
  slug: string;
}
export interface Multilingual {
  es: string;
  en: string;
}

export interface Dimensions {
  height: string;
  length: string;
  width: string;
}

export type Address = {
  country: string;
  state: string;
  city: string;
  street: string;
  phone: string;
  fullName: string;
  postalCode?: string;
  number?: string;
  id: string;
  additionalComments: string;
  createdAt: Date | string;
  userId?: string;
  isBilling: boolean;
  isShipping: boolean;
  isPrimaryBilling: boolean;
  isPrimaryShipping: boolean;
};

export type OfflineAddress = {
  country: string;
  state: string;
  city: string;
  street: string;
  phone: string;
  fullName: string;
  postalCode?: string;
  number?: string;
  additionalComments: string;
  createdAt: Date;
  isBilling: boolean; 
  isShipping: boolean; 
  isPrimaryBilling: boolean;
  isPrimaryShipping: boolean;
};

export interface User {
  id: string;
  uid: string;
  address: Address;
  birthday: string;
  displayName: string;
  email: string;
  firstName: string;
  firstSurname: string;
  nickname: string;
  phone: string;
  picture: string;
  role: string;
  secondName: string;
  secondSurname: string;
  session: {
    idToken: string | null;
    refreshToken: string | null;
  };
  strikes: number;
}
export interface OfflineUser {
  address?: Address;
  birthday?: string;
  email: string;
  firstName: string;
  firstSurname: string;
  // nickname?: string;
  phone: string;
  secondName?: string;
  secondSurname?: string;
}

export enum DBCountryReference {
  USA = "USA",
  CAN = "CANADA",
  MEX = "MEXICO",
  BRA = "BRAZIL",
  ESP = "SPAIN",
  FRA = "FRANCE",
  DEU = "GERMANY",
  ITA = "ITALY",
  JPN = "JAPAN",
  CHN = "CHINA",
  COL = "COLOMBIA",
  ARG = "ARGENTINA",
  PAN = "PANAMA",
  VEN = "VENEZUELA",
  CRC = "COSTA RICA",
  ARV = "ARUBA",
}

export enum DBLanguageReference {
  en = "en",
  es = "es",
  fr = "fr",
  de = "de",
  it = "it",
  jp = "jp",
  zh = "zh",
  pt = "pt",
}

// {
//   id: string;
//   imagesReference: string;
//   name: string;
//   url: string;
//   length: number;
// }
export type ProductsCategoryType = 'machinery' | 'store' | 'wholesale';
export interface Product {
  categories: { name: string; id: string; slug: string }[];
  createdAt: { seconds: number; nanoseconds: number };
  description: { en: string; es: string };
  id: string | number;
  images: string[];
  videos?: string[]
  imagesReference: string;
  name: { en: string; es: string };
  currency: string;
  price: number;
  priceType: PriceType;
  rating: string;
  ratingCount: number;
  regularPrice: number | null;
  salePrice: number | null;
  shortDescription: { en: string; es: string };
  sku: string;
  status: string;
  tags: [];
  type: string;
  updatedAt: { seconds: number; nanoseconds: number };
  virtual: boolean;
  weight: string;
  wooId: number;
  idCategory: number;
  mainProductCategory: ProductsCategoryType;
  shippingTimeDays: string;
  countryOrigin: string;

}

export type ImageResponsive = {
  imageDesktop: string;
  imageMobile: string;
};

export interface Solution {
  image: string;
  name: {
    en: string;
    es: string;
  };
  shortDescription: {
    en: string;
    es: string;
  };
}

// #PAYMENTS
export interface CartItemPaymentCheckout {
  name: string;
  price: number;
  quantity: number;
  image: string;
  invoiceId?: string;
}

export interface PaymentsProps {
  userUid: string | null;
  orderId: number | string | null;
  orderUid: string | null;
  items: CartItemPaymentCheckout[];
  typeTransaction:   'product_payment' | 'service_payment'
  paymentMethodId: number;
  shippingAddressId?: string | null;
  shippingCost: number;
  taxAmount: number;
  currency: string;
  creditCardTransactionId?: string;
  paymentImageCapture?: string;
  paypalTransactionId?: string;
  backupPaymentCaptureReference?: string[];
  language: DBLanguageReference;
  // offline props
  userInfo?: OfflineUser;
  paymentId: string;
  address?: OfflineAddress;
  country: string;
}

export interface INeedRequestItem {
  needId: string;
  needCategory: string;
  needTitle: string;
  needReference: string;
  needDescription: string;
  needUrgencyLevel: string;
  needImage?: string;
  status: string;
  date: Date;
}

export interface CreateNeedRequestProps {
  userId: string;
  country: string;
  needData : {
      needCategory: string;
      needTitle: string;
      needReference: string;
      needDescription: string;
      needUrgencyLevel: string;
      needImage?: string;
  }
}

export interface CreateNeedRealStateLandingType {
  options: string[],
  urgencyLevel: string,
  needDescription: string
}

export type PaymentMethods = "card" | "yappy" | "ach" | "cash" | "paypal";

export type StatusPayment = "pending" | "approved" | "rejected";

export interface UserOrder {
  id: string;
  numericOrderId: number;
  invoiceId: string;
  paymentImageCapture: string | null;
  paymentMethod: PaymentMethods;
  shippingAddressId: string;
  date: { seconds: number; nanoseconds: number };
  shippingCost: number | null;
  status: StatusPayment;
  timestamp: { seconds: number; nanoseconds: number };
  taxAmount: number | null;
  transactionId: string | null;
  totalAmount: number;
  totalItemsAmount: number;
  userUid: string;
  items: CartItemPaymentCheckout[];
}


export interface ImageObject {
  id: string | number;
  image: string;
  link: string;
}


export interface UserBasicDataFormProps {
  firstName?: string;
  firstSurname?: string;
  email?: string;
  phoneNumber?: string;
  phone?: string;
  picture?: string;
  country?: string;
  city?: string;
  state?: string;
}
import {useDispatch, useSelector} from 'react-redux'
import {userSelector} from 'redux/user'
import {useEffect} from 'react'
import {setAuth} from 'redux/auth'

const UseAuthentication = (): void => {

  const user = useSelector(userSelector)
  const dispatch = useDispatch()

  useEffect(() => {
    if (user?.userData !== null && user?.userData.length > 10) {
      dispatch(setAuth({ isAuth: true }))
    } else {
      dispatch(setAuth({ isAuth: false }))
    }
    
  }, [user.token, user, dispatch])
}

export default UseAuthentication

import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { THEME } from 'constants/theme/theme';
import { useNotification } from 'libs/context/AlertContext';
import { useTranslation } from 'react-i18next';

interface IDatePickerProps {
    label: string;
    onChange: (value: string) => void;
    useISO8601?: boolean;
    defaultValue?: dayjs.Dayjs | string;
    disableFullWidth?: boolean;
    value?: dayjs.Dayjs | string;
    clearDate?: boolean;
    onlyNewDate?: boolean;
    minAge?: number; 
    maxAge?: number; 
}

export default function DatePicker(props: IDatePickerProps) {
    const { 
        label, 
        onChange, 
        useISO8601, 
        defaultValue, 
        disableFullWidth, 
        value, 
        clearDate, 
        onlyNewDate,
        minAge = 0,
        maxAge = 0 
    } = props;

    const { t } = useTranslation();

    const { showWarning } = useNotification();
    
    const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | string | null>(
        defaultValue ? dayjs(defaultValue) : null
    );

    const minDate = minAge > 0 ? dayjs().subtract(minAge, 'year') : undefined;
    const maxDate = maxAge > 0 ? dayjs().subtract(maxAge, 'year') : undefined;

    useEffect(() => {
        if (value) {
            setSelectedDate(value);
        } else {
            if (value === '') {
                setSelectedDate(null);
            }
        }

        if (clearDate === true) {
            setSelectedDate(null);
        }

    }, [value, clearDate]);

    // const handleDateError = (error: any) => {
    //     if (error === 'invalidDate' && onlyNewDate) {
    //         alert('Solo puede seleccionar días después de hoy');
    //     }
    // };

    const handleDateError = (error: any) => {
        if (error === 'invalidDate' && onlyNewDate) {
            alert(t('only_new_date_error'));
        }
    };

    const validateDate = (date: dayjs.Dayjs | null) => {
        if (date) {
            if ((minDate && date.isBefore(minDate)) || (maxDate && date.isAfter(maxDate))) {
                showWarning(
                    t('date_range_warning', {
                        minDate: minDate?.format('MM/DD/YYYY') || '01/01/1940',
                        maxDate: maxDate?.format('MM/DD/YYYY')
                    })
                );
                setSelectedDate(null);
                onChange('');
                return false;
            }
        }
        return true;
    };
    

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
            <MuiDatePicker
                label={label ? t(label) : t('default_label')}
                value={selectedDate ? dayjs(selectedDate) : null}
                minDate={minDate}
                maxDate={maxDate}
                onError={handleDateError}
                onChange={(newValue) => {
                    if (validateDate(newValue)) {
                        if (useISO8601 === true) {
                            const date: any = newValue ? newValue.format() : '';
                            onChange(date);
                        } else {
                            const date: any = newValue ? newValue.format('MM/DD/YYYY') : '';
                            onChange(date);
                        }
                        setSelectedDate(newValue);
                    }
                }}
                sx={{
                    '&.MuiFormControl-root': {
                        width: disableFullWidth ? 'auto' : '100%',
                    },
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: THEME.colors.primary,
                            borderRadius: '0.6rem',
                            border: `2px solid ${THEME.colors.primary}`
                        },
                        '&:hover fieldset': {
                            borderColor: THEME.colors.primary,
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: THEME.colors.primary,
                        },
                    },
                }}
            />
        </LocalizationProvider>
    );
}